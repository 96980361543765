import { getConfig } from "../../../config/index"
import { PaymentType, api, RecipientType } from "model"

export const getBacsPayments = async (token: string): Promise<PaymentType[]> => {
  return api.platformGet(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/payments/get-bacs-payments`,
    token
  )
}

export const getPendingPayments = async (token: string): Promise<PaymentType[]> => {
  return api.platformGet(
    `${getConfig().baseUrl}/wp-json/social-supermarket/v1/platform/payments/get-pending-payments`,
    token
  )
}

export const approvePayment = async (
  token: string,
  paymentIntentId: string
): Promise<RecipientType[]> => {
  return api.platformGet(
    `${
      getConfig().baseUrl
    }/wp-json/social-supermarket/v1/platform/payments/approve-payment?paymentIntentId=${paymentIntentId}`,
    token
  )
}
