import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import TrueFalseRenderer from "../../../components/grid/TrueFalseRenderer"
import { AgGridColumn, AgGridReact } from "ag-grid-react"
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community/dist/lib/events"
import { GridApi } from "ag-grid-community"
import { PaymentType } from "model"

const Container = styled.div`
  .ag-row {
    cursor: pointer;
  }
`

let gridApi: GridApi = null

interface Props {
  payments: PaymentType[]
  onPaymentSelected: (payment: PaymentType) => void
}

const AdminPendingPaymentsList: FC<Props> = ({ payments, onPaymentSelected }) => {
  const handleGridReady = (gridReady: GridReadyEvent) => {
    gridApi = gridReady.api
    gridReady.api.sizeColumnsToFit()
  }

  const handleRowClicked = (event: RowClickedEvent) => {
    onPaymentSelected(event.data)
  }

  return (
    <Container className="ag-theme-balham">
      <AgGridReact
        rowData={payments}
        onGridReady={handleGridReady}
        rowSelection={"single"}
        onRowClicked={handleRowClicked}
        suppressCellSelection={true}
        domLayout={"autoHeight"}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        frameworkComponents={{
          trueFalseRenderer: TrueFalseRenderer,
        }}
      >
        <AgGridColumn field="ID" />
        <AgGridColumn field="amount" />
        <AgGridColumn field="date" />
        <AgGridColumn field="name" />
        <AgGridColumn field="email" />
        <AgGridColumn field="orderId" />
        <AgGridColumn field="email" />
        <AgGridColumn field="status" />
      </AgGridReact>
    </Container>
  )
}

export default AdminPendingPaymentsList
