import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import OrderType from "../../model/OrderType"
import SecondaryButton from "../../components/button/SecondaryButton"
import * as orderApi from "../../api/platform/orderApi"
import { LoginContext, PrimaryButton, Spinner } from "components"
import { colors } from "model"

const Container = styled.div`
  background-color: white;
  padding: 15px;
  margin: 5px;
  margin-bottom: 10px;
  position: relative;
`
const GiftNote = styled.div`
  border: 1px dashed ${colors.borderGrey};
  background-color: ${colors.offWhite};
  padding: 30px;
  border-radius: 10px;
  font-size: 0.9em;
`
const Hint = styled.div`
  font-size: 0.9em;
  color: ${colors.darkBorderGrey};
  margin-bottom: 20px;
`
const StyledPrimaryButton = styled(PrimaryButton)`
  &&& {
    width: 170px;
    margin-top: 20px;
    font-size: 0.8em;
  }
`
const StyledSecondaryButton = styled(SecondaryButton)`
  width: 170px;
  margin-top: 20px;
  font-size: 0.8em;
  margin-left: 10px;
`
const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
`
const Textarea = styled.textarea`
  width: 100%;
  height: 200px;
`

interface Props {
  order: OrderType
  setOrder: (order: OrderType) => void
}

const GiftNoteConfig: FC<Props> = ({ order, setOrder }) => {
  const { token, initialising } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [editingNote, setEditingNote] = useState<string>("")

  useEffect(() => {
    setEditingNote(order.giftNote)
  }, [order])

  const handleCancel = () => {
    setIsEditing(false)
    setEditingNote(order.giftNote)
  }

  const handleSave = async () => {
    try {
      setIsLoading(true)
      const newOrder = await orderApi.updateGiftNote(token, order.key, editingNote)
      setIsEditing(false)
      setOrder(newOrder)
    } catch (e) {
      console.log(`Unexpected error, please contact support: ${e}`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} isLocal />
      <b>Gift Note</b>
      <Hint>This gift note will be added to the products that are dispatched.</Hint>
      {!isEditing && (
        <div>
          <GiftNote
            dangerouslySetInnerHTML={{
              __html: order.giftNote.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
            }}
          />
          <StyledPrimaryButton onClick={() => setIsEditing(true)}>Edit</StyledPrimaryButton>
        </div>
      )}
      {isEditing && (
        <div>
          <Textarea
            value={editingNote}
            maxLength="200"
            onChange={event => setEditingNote(event.target.value)}
          />
          <Buttons>
            <StyledPrimaryButton onClick={handleSave}>Save</StyledPrimaryButton>
            <StyledSecondaryButton onClick={handleCancel}>Cancel</StyledSecondaryButton>
          </Buttons>
        </div>
      )}
    </Container>
  )
}

export default GiftNoteConfig
